<template>
  <div class="stage_page">
    <div class="child-top" style="padding-top: 0;">
      <div class="input-from">
        <el-date-picker
          v-model="date"
          type="month"
          align="right"
          unlink-panels
          value-format="yyyy-MM"
          placeholder="选择月"
          :picker-options="pickerOptions"
          @change="deparmentChange"
          class="picker_date">
        </el-date-picker>
          <!-- :format="dateList[0] + ' 至 ' + dateList[6]" -->
        <!-- <el-date-picker
          v-model="date"
          type="week"
          placeholder="选择周">
        </el-date-picker> -->
        <el-cascader
            style="float: left;"
            placeholder="请选择运动队"
            clearable
            @change="deparmentChange"
            v-model="deparmentId"
            :options="departmentOptions"
            :show-all-levels="false"
            collapse-tags
            :props="{children: 'children', label: 'name', value: 'uuid', emitPath: false}"></el-cascader>
        <div>
            <!-- @keyup.enter.native="create(1)"
            @clear="create(1)" -->
          <el-input
            class="left-child-input left-child-input-content"
            clearable
            placeholder="请输入运动员姓名"
            style="width: 200px; float: left;"
            suffix-icon="iconfont iconsousuo"
            v-model="inputValue"
          >
          </el-input>
          <el-button @click="create" class="searchBtn" round>搜索</el-button>
        </div>
      </div>
      <div class="operation-button">
        <el-button
          class="new_btn"
          operation-button
          round
          @click="monthPlanAdd"
          :disabled="$store.getters.permissionsStr('新增')"
        >
          <span>新增阶段计划</span>
        </el-button>
      </div>
    </div>
    <div class="stage_content" v-loading="contentLoading">
      <div class="stage_content_bot" v-for="(item, index) in tableList" :key="index">
        <div class="content_bot_top">{{item.start_date.slice(0, 4)}}年  {{item.start_date.slice(5, 7)}}-{{item.start_date.slice(8, 10)}}/{{item.end_date.slice(5, 7)}}-{{item.end_date.slice(8, 10)}}</div>
        <ul class="content_bot_con" @click="monthPlanEdit(item.uuid)">
          <li><i style="background: #009DD9;"></i><p><span>所属阶段</span>：{{item.stage}}</p></li>
          <li><i style="background: #009933;"></i><p><span>训练目标</span>：{{item.target}}</p></li>
          <li><i style="background: #FF8326;"></i><p><span>运动员</span>：{{item.staff_quantity}}人 
              <el-tooltip class="item" effect="dark" :content="title" placement="right">
                <el-button type="text" @mouseenter.native="getTitle(item.uuid)">查看人员>></el-button>
              </el-tooltip>
          </p></li>
          <i class="el-icon-paperclip file_icon"  @click.stop="uploadBtn(item)" :style="item.annex_quantity ? 'color: #0055E9;' : ''"></i>
          <i class="el-icon-delete del_icon" @click.stop="delList(item.uuid)"></i>
        </ul>
      </div>
    </div>
    <el-pagination
      :current-page.sync="currentPage"
      :page-size="9"
      :total="tableTotal"
      @current-change="handleCurrentChange"
      class="table-pagination"
      layout="total, prev, pager, next , jumper"
    ></el-pagination>

    <el-dialog
      :title="dialogTitle ? '新增阶段训练计划' : '编辑阶段训练计划'"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
       v-loading="contentLoading"
      :show-close="false"
      class="monthDialogContent"
      center>
      <div class="dialogContent_top">
        <el-date-picker
          v-model="valueDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <div class="top_left">
          <span><strong>*</strong>选择运动队</span>
          <el-cascader
            placeholder="请选择运动队"
            clearable
            v-model="addDeparmentId"
            :options="departmentOptions"
            :show-all-levels="false"
            collapse-tags
            @change="getStaffList"
            :props="{children: 'children', label: 'name', value: 'uuid', emitPath: false}"></el-cascader>
        </div>
        <div class="top_right">
          <span><strong>*</strong>选择队员</span>
          <el-select v-model="checkMember" multiple @change="memberChange">
            <el-option v-for="(item, index) in staffByList" :key="index" :label="item.name" :value="item.uuid"></el-option>
          </el-select>
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="100px" class="ruleForm" label-position="left" :rules="formRules">
        <el-form-item label="所属阶段" prop="stage">
          <el-input v-model="form.stage" placeholder="请输入所属阶段"></el-input>
        </el-form-item>
        <el-form-item label="训练目标" prop="target">
          <el-input v-model="form.target" placeholder="请输入训练目标"></el-input>
        </el-form-item>
        <el-form-item label="监测指标">
          <el-input v-model="form.monitor_indicators" placeholder="请输入监测指标"></el-input>
        </el-form-item>
        <el-form-item label="训练负荷">
          <el-input v-model="form.loads" placeholder="请输入训练负荷"></el-input>
        </el-form-item> 
        <el-form-item label="主要力量练习">
          <el-input v-model="form.major_power" placeholder="请输入主要力量练习内容"></el-input>
        </el-form-item> 
        <el-form-item label="训练量">
          <el-input v-model="form.train_quantity" placeholder="请输入此阶段内训练量情况"></el-input>
        </el-form-item> 
        <el-form-item label="训练强度">
          <el-input v-model="form.train_strength" placeholder="请输入此阶段内训练强度情况"></el-input>
        </el-form-item> 
        <el-form-item label="体能测试">
          <el-input v-model="form.physical_test" placeholder="请输入此阶段体能测试情况"></el-input>
        </el-form-item> 
        <el-form-item label="阶段训练内容">
          <el-input v-model="form.stage_content" type="textarea" :rows="3" placeholder="请输入阶段训练内容"></el-input>
        </el-form-item> 
      </el-form>
      <div class="form-bottom-button">
        <el-button @click="PlanSave" type="primary" v-no-more-click class="save_btn" round
          >保存</el-button
        >
        <el-button @click="PlanCancel" class="cancel_btn" round>取消</el-button>
      </div>
    </el-dialog>

    <!-- 附件弹框 -->
    <el-dialog
      :title="`附件列表 (${fileNum})`"
      :visible.sync="fileDialogVisible"
      :close-on-click-modal="false"
       v-loading="contentLoading"
      width="50%"
      :show-close="false"
      center
      class="newDialog">
      <div class="form_bot">
        <div class="inter_opBtn" v-loading="fileLoading">
          <el-upload
            class="upload-demo"
            :action="`${$store.state.img_url}/p/annex/add`"
            :data="{'master_uuid': fileListUuid, 'last_path': '4'}"
            :on-success="success"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :file-list="newFileList">
            <el-button type="primary" round>上传</el-button>
          </el-upload>
          <el-button type="primary" @click="upload_down" round>下载</el-button>
          <el-button type="primary" round @click="option_del">删除</el-button>
        </div>
        <el-checkbox-group v-model="checkList">
          <ul class='upload_ul'>
              <li v-for="(item, index) in files" :key="index">
                <div :class="checkList.indexOf(item.name) != -1 ? 'img_option img_active' : 'img_option'" @click="fileTofile(item)">
                  <img :src="item.url" alt="">
                  <div class="img_dialog" v-if="checkList.indexOf(item.name) != -1">

                  </div>
                </div>
                <el-checkbox :label="item.name">{{index + 1}}</el-checkbox>
                <p>{{item.name}}</p>
              </li>
          </ul>
        </el-checkbox-group>
      </div>
      
      <el-dialog :visible.sync="imgDialog" append-to-body class="append_dialog">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <span slot="footer" class="form-bottom-button">
        <!-- <el-button @click="fileSave" class="save_btn" round>保 存</el-button> -->
        <el-button @click="fileClose" class="cancel_btn" round>关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      tableTotal: 0,
      date: '',
      dateList: [],
      title: '',
      deparmentId: '',
      departmentOptions: [],
      pickerOptions: {},
      tableList: [],
      dialogTitle: true,
      dialogVisible: false,
      contentLoading: false,
      valueDate: '',
      form: {},
      addDeparmentId: '',
      // 选择队员
      checkMember: [],
      staffByList: [],
      formRules: {
        stage: [
          { required: true, message: '请输入所属阶段', trigger: 'blur' }
        ],
        target: [
          { required: true, message: '请输入训练目标', trigger: 'blur' }
        ]
      },

      inputValue: '',
      starDate: '',
      endDate: '',

      fileListUuid: '',
      files: [],
      checkList: [],
      newFileList: [],
      fileNum: 0,
      fileDialogVisible: false,
      fileLoading: false,
      imgDialog: false, // 附件图片查看
      dialogImageUrl: '', // 附件查看图片地址
    }
  },
  mounted() {
    this.getDeparment().then((resolve, reject) => {
      this.create()
    })
  },
  methods: {
    create() {
      this.contentLoading = true
      this.$axios.post("/p/stageTrain/lists", this.$qs({
        page: this.currentPage,
        page_size: 9,
        department_uuid: this.deparmentId,
        start_date: this.starDate,
        end_date: this.endDate,
        keyword: this.inputValue
      })).then(res => {
        if(res.data.code == 0) {
          this.tableTotal = res.data.data.total
          this.tableList = res.data.data.rows
        }
        setTimeout(() => {
          this.contentLoading = false
        }, 100)
      }).catch(error => {
        this.contentLoading = false
      })
    },
    // 获取参赛人员
    getTitle(uuid) {
      this.$axios.post("/p/stageTrain/getStaffByUUid", this.$qs({
        uuid: uuid
      })).then(res => {
        if(res.data.code == 0) {
          this.title = res.data.data
        }
      })
    },
    monthPlanAdd() {
      this.dialogVisible = true
      this.dialogTitle = true
      this.addDeparmentId = this.deparmentId
      this.getStaffList()

    },
    deparmentChange() {
      this.getMonthDay(this.date)
      this.create()
    },
    delList(uuid) {
      if(this.$store.getters.permissionsStr('删除')) {
        return this.$message({
          message: '暂无权限',
          type: 'warning'
        })
      }
      this.$axios.post("/p/stageTrain/del", this.$qs({
        uuid: uuid
      }))
        .then(res => {
          if(res.data.code == 0) {
            this.$message({
              type: 'success',
              message: res.data.message
            })
            this.create()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        });
    },
    PlanSave() {
      if(!this.valueDate) {
        this.$message({
          type: 'error',
          message: '请选择阶段日期'
        })
        return false
      } else if(this.addDeparmentId == '') {
        this.$message({
          type: 'error',
          message: '请选择运动队'
        })
        return false
      } else if(!this.checkMember.length) {
        this.$message({
          type: 'error',
          message: '请选择队员'
        })
        return false
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let obj = {
            department_uuid: this.addDeparmentId,
            staff_uuid_str: this.checkMember.toString(','),
            start_date: this.valueDate[0],
            end_date: this.valueDate[1],
            ...this.form
          }
          let url = ''
          if(this.dialogTitle) {
            url = '/p/stageTrain/add'
          } else {
            url = '/p/stageTrain/update'
          }
          this.$axios.post(url, this.$qs(obj)).then(res => {
            if(res.data.code == 0) {
              this.$message({
                type: 'success',
                message: res.data.message
              })
              this.create()
              this.PlanCancel()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    PlanCancel() {
      this.dialogVisible = false
      this.valueDate = []
      this.checkMember = []
      this.addDeparmentId = ''
      this.$refs['form'].resetFields()
      this.form = {}
    },
    monthPlanEdit(uuid) {
      this.dialogVisible = true
      this.dialogTitle = false
      this.contentLoading = true
      this.$axios.post("/p/stageTrain/read", this.$qs({
        uuid: uuid
      })).then(res => {
        if(res.data.code == 0) {
          this.form = {
            // annex_quantity: res.data.data.annex_quantity,
            loads: res.data.data.loads,
            major_power: res.data.data.major_power,
            monitor_indicators: res.data.data.monitor_indicators,
            physical_test: res.data.data.physical_test,
            staff_quantity: res.data.data.staff_quantity,
            stage: res.data.data.stage,
            stage_content: res.data.data.stage_content,
            target: res.data.data.target,
            train_quantity: res.data.data.train_quantity,
            train_strength: res.data.data.train_strength,
            uuid: res.data.data.uuid
          }
          this.valueDate = [res.data.data.start_date, res.data.data.end_date]
          this.addDeparmentId = res.data.data.department_uuid
          this.$axios.post("/p/Staff/getStaffByStation", this.$qs({
            department_uuid: this.addDeparmentId,
            type: 'staff'
          }))
            .then(rs => {
              if(rs.data.code == 0) {
                this.staffByList = rs.data.data
                this.staffByList.unshift({
                  name: '全部',
                  uuid: '0'
                })
                this.checkMember = res.data.data.staff_uuid_str.split(',')
              } else {
                this.$message({
                  type: 'error',
                  message: rs.data.message
                })
              }
              this.contentLoading = false
            });
        }
      })
    },
    getDeparment () {
      return new Promise((resolve, reject) => {
        this.$axios.post("/p/staff/tree")
        .then(res => {
          if(res.data.code == 0) {
            this.departmentOptions =
              res.data.data.department 
            // this.deparmentId = this.departmentOptions[0].children ? this.departmentOptions[0].children[0].uuid : this.departmentOptions[0].uuid
            this.deparmentId = getUuid(this.departmentOptions)
            function getUuid (data) {
              if(data[0].children) {
                return getUuid(data[0].children)
              } else {
                return data[0].uuid
              }
            }
            resolve()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
            reject()
          }
        });
      })
    },
    // 获取运动员
    getStaffList() {
      this.checkMember = []
      if(!this.addDeparmentId) {
        this.staffByList = []
        return
      }
      this.$axios.post("/p/Staff/getStaffByStation", this.$qs({
        department_uuid: this.addDeparmentId,
        type: 'staff'
      }))
        .then(res => {
          if(res.data.code == 0) {
            this.staffByList = res.data.data
            this.staffByList.unshift({
              name: '全部',
              uuid: '0'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        });
    },
    memberChange() {
      let index = this.checkMember.indexOf('0')
      if(index !== -1) {
        let data = []
        this.staffByList.forEach(itm => {
          data.push(itm.uuid)
        })
        this.checkMember = data
        this.checkMember.splice(0, 1)
      }
    },
    weekPlanAdd() {

    },
    getMonthDay(date) {
      if(!date) {
        this.starDate = ''
        this.endDate = ''
        return false
      }
      var nowDate = new Date(date);
      var cloneNowDate = new Date(date);
      var fullYear = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
      var endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
      function getFullDate(targetDate) {
              var D, y, m, d;
              if (targetDate) {
                  D = new Date(targetDate);
                  y = D.getFullYear();
                  m = D.getMonth() + 1;
                  d = D.getDate();
              } else {
                  y = fullYear;
                  m = month;
                  d = date;
              }
              m = m > 9 ? m : '0' + m;
              d = d > 9 ? d : '0' + d;
              return y + '-' + m + '-' + d;
          };
      var endDate = getFullDate(cloneNowDate.setDate(endOfMonth));//当月最后一天
      var starDate = getFullDate(cloneNowDate.setDate(1));//当月第一天
      this.starDate = starDate
      this.endDate = endDate
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.create()
    },

    // ***************** 附件上传
    // 附件弹框
    uploadBtn(rows) {
      if(this.$store.getters.permissionsStr('附件')) {
        return this.$message({
          message: '暂无权限',
          type: 'warning'
        })
      }
      this.contentLoading = true
      this.fileDialogVisible = true
      this.fileNum = rows.annex_quantity
      this.fileListUuid = rows.uuid

      this.$axios.post("/p/annex/lists", this.$qs({
        uuid: rows.uuid
      })).then(res => {
        if(res.data.code == 0) {
          this.files = res.data.data.rows
          res.data.data.rows.forEach((itm, index) => {
            // item.url = 'http://' + item.file_path
            let type = this.nameMatch(itm)
            if(type) {
              this.files[index].url = type
            } else {
              this.files[index].url = 'http://' + this.files[index].file_path
            }
          })
          this.newFileList = res.data.data.rows
        }
        setTimeout(() => {
          this.contentLoading = false
        }, 200)
      })
    },
    // 附件上传拦截
    beforeUpload(file) {
      const size = file.size / 1024 / 1024
      if (size > 20) {
        this.$message({
          title: 'warning',
          message: '文件大小超过20M'
        })
        return false
      } else {
        return true 
      }
    },
    // 附件下载
    upload_down() {
      if(!this.checkList.length) {
        this.$message({
          type: 'error',
          message: '请选择要下载的附件'
        })
        return false  
      }
      let arr = []
      this.checkList.forEach((item, index) => {
        this.files.forEach(itm => {
          if(item == itm.name) {
            arr.push(itm.file_path)
          }
        })
      })

      let path = "";
      arr.forEach((i, k) => {
        if (k == arr.length - 1) {
          path += `file_array[${k}]=${i}`;
        } else {
          path += `file_array[${k}]=${i}&`;
        }
      });
      
      let url = this.$store.state.img_url + "/p/download/zip?" + path;
      window.open(url);
    },
    // 附件删除
    option_del() {
      let arr = ''
      this.checkList.forEach((item, index) => {
        this.files.forEach((itm, ix) => {
          if(item == itm.name) {
            if(ix == 0) {
              arr = itm.file_path
            } else {
              arr += ',' + itm.file_path 
            }
          }
        })
      })

      this.$axios
        .post(
          `/p/annex/delete`,
          this.$qs({
            file_path: arr
          })
        ).then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message
            });
            this.fileNum = this.fileNum - this.checkList.length
            let checkLength = this.checkList.length
            while(checkLength--) {
              var length = this.files.length
              while(length--) {
                if(this.files[length].name == this.checkList[checkLength]) {
                  this.files.splice(length, 1)
                  this.checkList.splice(checkLength, 1)
                }
              }
            }
            this.create()
          } else {
            this.$message({
              type: "error",
              message: res.data.message
            });
          }
        });
    },
    //成功的时候
    success (res, file, fileList) {
      if (res.code == 0) {
        this.fileNum ++
        this.files.push(file.response.data);
        // this.$message({
        //   type: "success",
        //   message: res.message
        // });
        this.create()
      } else {
        this.$message({
          type: "error",
          message: res.message
        });
        for (let i in fileList) {
          if (fileList[i].name == file.name) {
            fileList.splice(i, 1);
          }
        }
      }
      let type = this.nameMatch(file)
      if(type) {
        this.files.forEach(item=> {
          if(item.name == file.name) {
            item.url = type
          }
        })
      } else {
        this.files.forEach(item => {
          if(item.name == file.name) {
            item.url = 'http://' + item.file_path
          }
        })
      }
    },
    // 附件弹框关闭
    fileClose() {
      this.fileDialogVisible = false
      // this.create(this.currentPage)
    },
    nameMatch(name) {
      let suffix = '' // 后缀
      let result = '' // 类型结果
      let imgUrl = ''

      var fileArr = name.name.split('.')
      suffix = fileArr[fileArr.length - 1]

      if(!suffix) {
        result = false
        return result
      }

      let typeList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
      result = typeList.some(item => {
        return item == suffix
      })
      if(result) {
        return false
      }

      switch(suffix) {
        case 'docx':
          imgUrl = this.wordUrl
          break;
        case 'rtf':
          imgUrl = this.wordUrl
          break;
        case 'xls':
          imgUrl = this.xlsxUrl
          break;
        case 'xlsx':
          imgUrl = this.xlsxUrl
          break;
        case 'ppt':
          imgUrl = this.pptsUrl
          break;
        case 'pptx':
          imgUrl = this.pptsUrl
          break;
        case 'pdf':
          imgUrl = this.pdfUrl
          break;
       default:
          imgUrl = this.morenUrl
          break;
      }
      return imgUrl
    },
    fileTofile(item) {
      this.imgDialog = true
      this.dialogImageUrl = item.url
    },
    // ********************
  }
}
</script>

<style lang="scss" scoped>
.stage_page{
  padding-top: 10px;
  .child-top {
    border-radius: 5px;
    margin-bottom: 0;
    .input-from {
      float: left;
      min-width: 1150px;
      margin-bottom: 0;

      .picker_date{
        float: left;
        margin-top: 20px;
        width: 240px;
      }

      & > .el-select {
        width: 150px;
        float: left;
        margin-top: 20px;
      }

      .el-select,
      .el-input {
        margin-top: 20px;
      }

      .el-input.el-input--suffix.left-child-input {
        float: left;
        width: 200px;
      }

      .el-cascader{
        margin-top: 18px;
        min-width: 150px;
      }

      .searchBtn{
        margin-top: 20px;
      }
      .el-date-editor{
        border-bottom: none;
      }
    }

    .operation-button {
      // float: left;
      margin-bottom: 10px;
    }
  }
  .stage_content{
    width: 100%;
    height: auto;
    background: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .stage_content_bot{
      width: 33%;
      .content_bot_top{
        background: #EEEEEE;
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #666;
        border: 1px solid #E2E2E2;
      }
      .content_bot_con{
        height: 160px;
        border: 1px solid #E2E2E2;
        border-top: none;
        padding-top: 27px;
        padding-right: 20px;
        box-sizing: border-box;
        position: relative;
        &:hover{
          .del_icon{
            color: #ED4A68;
            cursor: pointer;
          }
        }
        .file_icon{
          position: absolute;
          top: 13px;
          right: 15px;
          font-size: 25px;  
          color: #999999;
          &:hover{
            cursor: pointer;
          }
        }
        .del_icon{
          position: absolute;
          bottom: 18px;
          right: 20px;
          font-size: 25px;  
          color: #fff;
        }
        li{
          padding-left: 20px;
          font-size: 16px;
          color: #333333;
          margin-bottom: 10px;
          line-height: 25px;
          display: flex;
          p{
            flex: 1;
            overflow: hidden;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            .el-button{
              margin-left: 15px;
            }
          }
          i{
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
            background: #FF8326;
            margin-top: 7px;
          }
          span{
            width: 72px;
            display: inline-block;
          }
          .el-button{
            padding: 0;
          }
        }
      }
    }
  }
}
.table-pagination {
  text-align: right;
  margin-top: 0px;
  background: #fff;
  padding-bottom: 15px;
  /deep/ span {
    float: left;
  }
}
.monthDialogContent{
  padding: 0 30px;
  .dialogContent_top{
    display: flex;
    padding: 0 30px;
    strong{
      color: #f00;
      margin-right: 5px;
      font-size: 16px;
    }
    span{
      margin-right: 8px;
    }
    .top_left{
      width: 27%;
      margin-right: 20px;
    }
    .top_right{
      flex: 1;
      padding-right: 20px;
      display: flex;
      align-items: center;
      .el-select{
        flex: 1;
      }
    }
    .el-date-editor {
        margin-right: 20px;
        border-radius: 0;
        border-right-width: 0;
        border: none;
        border-bottom: 2px solid #ccc;

        &:focus {
            border-color: #0055E9;
        }
        .is-focus{
          .el-input__inner{
            border-color: #0055E9;
            &:focus {
              border-color: #0055E9;
            }
          }
        }
    }
  }
  .ruleForm{
    margin-top: 30px;
    padding-right: 45px;
    padding-left: 40px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    .el-input{
      /deep/ .el-input__inner {
        border-radius: 0;
        border-right-width: 0;
        border: 1px solid #ccc;
        border-radius: 5px;
        &:focus {
            border-color: #0055E9;
        }
      }
    }
  }
}
// 附件弹框样式
.newDialog{
  /deep/ .el-dialog{
    .form_bot{
      // height: 330px;
      overflow-x: auto;
      margin-top: 20px;
      border-radius: 5px;
      border: 1px solid #ccc;
      min-height: 200px;
    }
  }
}
.inter_upload{
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 200px;
}

.inter_opBtn{
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  padding-right: 30px;
  .upload-demo{
    margin-right: 10px;
  }
  .el-button{
    width: 110px;
    height: 40px;
    border: none;
    &:nth-child(1) {
      background: #35C8CB;
    }
    &:nth-child(2) {
      background: #6A6EF8;
    }
    &:nth-child(3) {
      background: #ED4A68;
    }
  }
}

.el-form{
  margin-bottom: 30px;
  .formItem_cen{
    display: flex;
    .el-form-item{
      flex: 1;
      .el-date-editor{
        width: 100%;
        border-bottom: none;
        .el-input__inner{
          border-color: #0055E9!important;
        }
      }
      .el-select{
        margin-top: 0;
        width: 100%;
      }
    }
  }
}
.inter_file{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  p{
    line-height: 16px;
    font-size: 17px;
    margin-bottom: 20px;
    span{
      display: inline-block;
      width: 5px;
      height: 16px;
      border-radius: 20px;
      background: #0055E9;
    }
  }
}

.upload_ul{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 20px 0;
  // padding-bottom: 10px;
  li{
    width: 150px;
    text-align: center;
    margin-left: 20px;
    position: relative;
    &:last-child{
      margin-right: 20px;
    }
    .el-checkbox{
      position: absolute;
      top: 0px;
      left: 0px;
      /deep/ .el-checkbox__inner{
        width: 20px;
        height: 20px;
        &::after{
          width: 6px;
          height: 13px;
          left: 5px;
        }
      }
      /deep/ .el-checkbox__label{
        display: none;
      }
    }
    &:hover{
      cursor: pointer;
    }
    &:first-child{
      margin-left: 20px;
    }
    .img_option{
      width: 150px;
      height: 150px;
      border-radius: 10px;
      border: 1px solid #ccc;
      overflow: hidden;
      position: relative;
      // border: 2px solid #fff;
      .img_dialog{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 85, 233, 0.11);
      }
    }
    .img_active{
      border: 1px solid #0055E9;
    }
    img{
      width: 100%;
      max-height: 150px;
    }
    p{
      margin: 10px;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
</style>